<template>
  <div class="Userhl_zhanlan Userhl_rightbox">
    <ul class="Userhl_zuopinul">
      <li class="Userhl_zuopinulli" v-for="(item, index) in list" :key="index">
        <div class="Userhl_zuopinulliimg" @click="zuopin(item.d_id)">
          <img :src="item.draw_pic" alt="" />
          <div class="Userhl_zuopinulliimg_sjtype">{{item.d_state==2?'待审核':(item.d_type==1?'毕设展':'设计展览')}}</div>
        </div>
        <p class="Userhl_zuopinulliname">{{ item.title }}</p>
        <div class="Userhl_zuopinullibtm">
          <div class="Userhl_zuopinullibtml">
            <icon-svg icon-class="iconlook" />
            <span>{{ item.browes == null ? 0 : item.browes }}</span>
            <icon-svg icon-class="iconmessages" />
            <span>{{ item.plnum == null ? 0 : item.plnum }}</span>
            <icon-svg icon-class="icongood" />
            <span>{{ item.give_like == null ? 0 : item.give_like }}</span>
          </div>
          <div class="Userhl_zuopinullibtmr">{{item.draw_start}}</div>
        </div>
      </li>
      <li v-if="Userhl_zuopinullizero" class="Userhl_zuopinulli Userhl_zuopinullizero"></li>
      <li class="lizero" v-if="lizero">
        <img
          src="https://task.hozoin.cn/Works/20210426/sj-3936605600.png"
          alt=""
        />
      </li>
    </ul>
     <el-button class="linkbtn" plain @click="linkxm('/Zhanping')">查看更多活动</el-button>
  </div>
</template>
<script>
export default {
  components: {},
  data() {
    return {
      img: "https://task.hozoin.cn/Works/20201104/sj-4369072618.png",
      list: [],
      limit: 10,
      userinfo: {},
      lizero:false,
      Userhl_zuopinullizero:false,
    };
  },
  methods: {
    linkxm(link) {
      this.$router.push({ path: link });
    },
    axiosqq(page) {
      var that = this;
      this.lizero=true;
      that
        .$axios({
          headers: { "X-CSRF-TOKEN": that.userinfo.token },
          url: "/api/getUserDraw",
          method: "post",
          data: {
            page,
            limit: that.limit,
            word_type: "2",
          },
        })
        .then((res) => {
          if (res.data.code == 1) {
            that.list = res.data.result.list;
            var lengthnum=res.data.result.list.length%3;
            if(lengthnum==2){
              that.Userhl_zuopinullizero=true;
            }
            this.lizero=false;
          } else {
            this.lizero=true;
          }
        });
    },
    zuopin(id) {
      this.$router.push({ path: "/Zhanping_bishe", query: { data: id } });
    },
  },
  created() {
    this.userinfo = JSON.parse(localStorage.getItem("userinfo"));
this.tokentimeyz();
    this.axiosqq(1);
  },
};
</script>
